import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './lib/string';

import Rollbar from 'rollbar';

import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';

import configureAmplify from './lib/configureAmplify';
import './lib/fecha';
import App from './App';
import * as serviceWorker from './serviceWorker';

Amplify.configure(configureAmplify);
const { REACT_APP_ROLLBAR_ACCESS_TOKEN, REACT_APP_VERSION } = process.env;

global.rollbar = new Rollbar({
	accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: (process.env.REACT_APP_STAGE === 'production' || process.env.REACT_APP_STAGE === 'staging'),
	payload: {
		environment: process.env.REACT_APP_STAGE,
		client: {
			javascript: {
				source_map_enabled: true,
				code_version: REACT_APP_VERSION,
				guess_uncaught_frames: true,
			},
		},
	},
});

// global.rollbar.info('Starting front');
global.isIEcancer = !!window.MSInputMethodContext && !!document.documentMode;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// eslint-disable-next-line no-console
console.info(`ℹ️ v${process.env.REACT_APP_VERSION}`);
